import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'
import { Notification, NotificationTypes } from '../../types'

import {
   UseNotifications,
   UseNotificationsOptions,
   NotificationsResponse,
   NotificationParams,
} from './useNotifications.type'

export default function (options: UseNotificationsOptions): UseNotifications {
   const {
      itemsPerPage = 10,
      onChange,
      totalItems = 0,
      notificationType,
   } = options

   const [currentPage, setCurrentPage] = useState(1)
   const request = useAPI()
   const { data, isLoading, isRefetching, refetch } = useQuery(
      ['notifications', { notificationType }],
      getFromAPI
   )
   const { mutate: doDismiss } = useMutation(dismissNotification, {
      onSettled: () => {
         refetch()
         onChange && onChange()
      },
   })
   const { mutate: doAcceptReject } = useMutation(
      ({ id, accept }) => updateNotification(id, accept),
      {
         onSettled: () => {
            refetch()
            onChange && onChange()
         },
      }
   )

   async function getFromAPI(): Promise<AxiosResponse<NotificationsResponse>> {
      const params: NotificationParams = {
         skip: (currentPage - 1) * itemsPerPage,
         take: itemsPerPage,
         category:
            notificationType === NotificationTypes.Global
               ? 1
               : notificationType,
         returnCompanyNotifications:
            notificationType === NotificationTypes.Global ? true : undefined,
      }

      try {
         const response = await request.get(PATH.DASHBOARD.NOTIFICATIONS, {
            params,
         })
         return response
      } catch (error) {
         throw error
      }
   }

   async function updateNotification(
      id: Notification['Id'],
      accept: boolean
   ): Promise<AxiosResponse> {
      return request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, { id, accept })
   }

   async function dismissNotification(
      id: Notification['Id']
   ): Promise<AxiosResponse> {
      return request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, {
         id,
         delete: true,
      })
   }

   const { mutate: doDeleteGlobal } = useMutation(deleteGlobalNotification, {
      onSettled: () => {
         refetch()
         onChange && onChange()
      },
   })

   async function deleteGlobalNotification(
      id: Notification['Id']
   ): Promise<AxiosResponse> {
      return request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, {
         id,
         delete: true,
      })
   }

   const deleteGlobal = (id: Notification['Id']) => {
      doDeleteGlobal(id)
   }

   const acceptReject = (id: Notification['Id'], accept: boolean) => {
      doAcceptReject({ id, accept })
   }

   const dismiss = (id: Notification['Id']) => {
      doDismiss(id)
   }

   const nextPage = () => {
      setCurrentPage(currentPage + 1)
   }

   useEffect(() => {
      refetch()
   }, [currentPage])

   const list = data?.data?.Data || []

   return {
      isLastPage: list.length === totalItems,
      isLoading: isLoading || isRefetching,
      isLoadingMore: isRefetching,
      list,
      acceptReject,
      dismiss,
      nextPage,
      deleteGlobal,
   }
}
